import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import general from "../css/General.module.css";
import { API_ACCESS_URL } from '../modals/Constants';

function UnderMaintainance() {
  const navigate = useNavigate();

  function checkStatus() {
    const fecthApiData = async (url, authSecret) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-app-status",
            AuthToken: authSecret,
          },
        });
        const data = await res.json();

        if (data.status_code === "true") {
          navigate("/", { replace: true });
        }
      } catch (error) {
      }
    };

    fecthApiData(API_ACCESS_URL);
  }

  useEffect(() => {
    checkStatus();
  });
  
  return (
    <div className={[general.viewCenter]}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.bgWhite,
          general.hideScrollBar,
        ].join(" ")}
      >

        <div
          className={[general.colView, general.viewCenter, general.pd5px15px, general.mgT50px].join(" ")}>
          <span className={[general.fontS25Px, general.mgT10px].join(" ")}>We'll be back soon!</span>
          <span className={[general.fontS16px, general.mgT10px].join(" ")}>*Currently we are under maintenance</span>
          <span
            className={[general.fontS16px, general.mgT15px].join(" ")}>Thanks for your patience</span>

          <div
            className={[general.viewCenter, general.pd5px20px, general.fontS18Px, general.mgT20px, general.colorWhite, general.bgCustomColor1, general.borderRadius10px].join(" ")}
            onClick={() => checkStatus()}>
            <span>Check Status</span>
            <img className={[general.heightWidth15px, general.mgL10px].join(" ")} src={require("../media/icons/refresh_white_icon.png")} alt='icon' />
          </div>
        </div>

      </div>
    </div>
  );
}

export default UnderMaintainance;