import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/AuthTopBar/TopBar";
import ToastDialog from "../dialogs/ToastDialog/toast";
import "../../MainStyle.css";
import {
  API_ACCESS_URL,
  IP_ACCESS_URL,
  getURLParam,
  generateAuthToken,
  IS_OTP_AVAILABLE,
  LIVE_CHAT_URL,
  openNewPage,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";

function ForgetPassword() {
  const Ref = useRef(null);
  const toastDialogRef = useRef(null);
  const navigate = useNavigate();
  const [resendOTPTimeLeft, setResendOTPTime] = useState(0);
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [isPasswordTypeShow, setPasswordType] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Forget Password",
    inMobileNum: "",
    inPassword: "",
    inConfirmPassword: "",
    inVerificationCode: "",
    isSessionExist: true,
    isOTPSending: false,
    resendOTPTimeLimit: 60,
  });

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updatePasswordtype = () => {
    if (isPasswordTypeShow) {
      setPasswordType(false);
    } else {
      setPasswordType(true);
    }
  };

  const updateOTPSendingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isOTPSending: data };
    });
  };

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const isMobileNumValidate = (mobilenum) => {
    if (mobilenum != "" && mobilenum != undefined && mobilenum.length == 10) {
      return true;
    } else {
      return false;
    }
  };

  const isPasswordValidate = (password, confirmPassword) => {
    if (
      password != "" &&
      password != undefined &&
      password.length >= 6 &&
      password == confirmPassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkForInputVal = (
    mobilenum,
    password,
    confirmPassword,
    verificationCode
  ) => {
    if (
      isMobileNumValidate(mobilenum) &&
      isPasswordValidate(password, confirmPassword) &&
      verificationCode.length > 4
    ) {
      setInValCorrect(true);
    } else {
      setInValCorrect(false);
    }
  };

  const onInputValChange = (source, data) => {
    if (source == "mobile") {
      checkForInputVal(
        data,
        pageConst.inPassword,
        pageConst.inConfirmPassword,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inMobileNum: data };
      });
    }

    if (source == "password") {
      checkForInputVal(
        pageConst.inMobileNum,
        data,
        pageConst.inConfirmPassword,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inPassword: data };
      });
    }

    if (source == "confirmPassword") {
      checkForInputVal(
        pageConst.inMobileNum,
        pageConst.inPassword,
        data,
        pageConst.inVerificationCode
      );

      setConstants((previousState) => {
        return { ...previousState, inConfirmPassword: data };
      });
    }

    if (source == "verificationCode") {
      checkForInputVal(
        pageConst.inMobileNum,
        pageConst.inPassword,
        pageConst.inConfirmPassword,
        data
      );

      setConstants((previousState) => {
        return { ...previousState, inVerificationCode: data };
      });
    }
  };

  const validateMyIP = (route) => {
    updateLoadingStatus(true);

    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.ip != "") {
          if (route == "OTP") {
            return sendVerificationCode(data.ip);
          } else {
            return validateResetPassword(data.ip);
          }
        } else {
          return "IPUNKNOWN";
        }
      } catch (error) {
        updateLoadingStatus(false);
        return "IPOUTERROR";
      }
    };

    requestAPI(IP_ACCESS_URL);
  };

  const validateResetPassword = (ip) => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: "route-reset-password",
            AuthToken: generateAuthToken(pageConst.inMobileNum, ip),
          },
          body: JSON.stringify(formData),
        });
        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "invalid_otp") {
          showToast(
            "OTP is incorrect ! Please enter correct OTP!"
          );
        } else if (data.status_code == "success") {
          showToast("Password Changed!", 2500, 0, 1);
        } else if (data.status_code == "account_error") {
          showToast(
            "Sorry, There is an error related to account !"
          );
        } else if (data.status_code == "invalid_mobile_num") {
          showToast("Invalid mobile number !");
        } else {
          showToast(
            "Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast(
          "There was a technical issue! Please try again!"
        );
      }
    };

    if (isInputValCorrect) {
      updateLoadingStatus(true);
      const formData = {
        MOBILE: pageConst.inMobileNum,
        NEW_PASSWORD: pageConst.inConfirmPassword,
        USER_OTP: pageConst.inVerificationCode,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startCountDownTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setResendOTPTime(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const getDeadTime = (e) => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + e);
    return deadline;
  };

  const clearTimer = (e) => {
    setResendOTPTime(30);

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startCountDownTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const sendVerificationCode = (ip) => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: "route-send-sms",
            AuthToken: generateAuthToken(pageConst.inMobileNum, ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);
        updateOTPSendingStatus(false);

        if (data.status_code == "otp_error") {
          showToast(
            "We failed to send OTP ! Please try again!"
          );
        } else if (data.status_code == "account_error") {
          showToast(
            "Sorry, There is an error related to account !"
          );
        } else if (data.status_code == "authorization_error") {
          showToast("Authorization Error !");
        } else if (data.status_code == "otp_limit_error") {
          showToast(
            "You've used daily limited OTP ! Please try later!"
          );
        } else if (data.status_code == "success") {
          setStorage(
            "otptimeout",
            getDeadTime(pageConst.resendOTPTimeLimit),
            30
          );
          clearTimer(getDeadTime(pageConst.resendOTPTimeLimit));
        } else {
          showToast(
            "Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateOTPSendingStatus(false);
        showToast(
          "There was a technical issue! Please try again!"
        );
      }
    };

    if (isMobileNumValidate(pageConst.inMobileNum)) {
      if (resendOTPTimeLeft > 0) {
        updateLoadingStatus(false);
        showToast("Please after sometime!");
      } else if (pageConst.isOTPSending == false) {
        updateOTPSendingStatus(true);
        const formData = {
          MOBILE: pageConst.inMobileNum,
          PURPOSE: "RESETPASSWORD",
        };
        requestAPI(API_ACCESS_URL, formData);
      }
    } else {
      updateLoadingStatus(false);
      showToast("Mobile Number is incorrect!");
    }
  };

  useEffect(() => {
    if (getStorage("uid")) {
      navigate("/home", { replace: true });
    } else {
      setConstants({ ...pageConst, isSessionExist: false });

      if (getStorage("otptimeout")) {
        let { total, seconds } = getTimeRemaining(getStorage("otptimeout"));
        if (seconds > 0) {
          clearTimer(getDeadTime(seconds));
        }
      }

      if (getURLParam("C") != null && getURLParam("C") != "") {
        let referCode = getURLParam("C");
        setConstants((previousState) => {
          return { ...previousState, inInviteCode: referCode };
        });
      }
    }
  }, []);

  return (
    <div
      className={[
        general.viewCenter,
        general.appBackground,
        pageConst.isSessionExist == true ? general.hideView : "",
      ].join(" ")}
    >
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.posRelative]}>
          <TopBar intentData={pageConst} multiBtn1="" />

          <div
            className={[
              styles.loginView,
              general.colView,
              general.flexDirColumn,
              general.width100,
              general.mgT50px,
            ].join(" ")}
          >
            {IS_OTP_AVAILABLE ? (
              <>
                <div
                  className={[
                    general.colView,
                    general.pd15px20px,
                    general.bgCustomColor1,
                  ].join(" ")}
                >
                  <span
                    className={[
                      general.colorWhite,
                      general.fontS18Px,
                      pageConst.pageTitle == "" ? general.hideView : "",
                    ].join(" ")}
                  >
                    {pageConst.pageTitle}
                  </span>
                  <span
                    className={[
                      general.colorWhite,
                      general.fontS13Px,
                      general.mgT10px,
                    ].join(" ")}
                  >
                    Please enter your mobile number to continue.<br></br>
                    If you need support then please contact us.
                  </span>
                </div>

                <div
                  className={[
                    styles.inputView,
                    general.colView,
                    general.width100,
                    general.mgT20px,
                  ].join(" ")}
                >
                  <div className={[general.rowView]}>
                    <img
                      src={require("../media/icons/cellphone_icon.png")}
                      alt="icon"
                    />
                    <label>Phone Number</label>
                  </div>

                  <div
                    className={[
                      general.width100,
                      general.rowView,
                      general.spaceBetween,
                      general.mgT10px,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        general.viewCenter,
                        general.height50px,
                        general.pd5px25px,
                        general.borderRadius10px,
                        general.bgCustomColor6,
                      ].join(" ")}
                    >
                      <span className={[general.colorWhite]}>+91</span>
                      <img
                        className={[
                          general.heightWidth18px,
                          general.mgL10px,
                          general.transformRotate90deg,
                        ].join(" ")}
                        src={require("../media/icons/angle_right_white_icon.png")}
                        alt="icon"
                      />
                    </div>
                    <input
                      type="number"
                      className={[
                        general.inputBox,
                        general.height50px,
                        general.mgL10px,
                        general.borderRadius10px,
                        general.bgCustomColor6,
                      ].join(" ")}
                      placeholder="Please enter phone number"
                      autoComplete="off"
                      onChange={(e) =>
                        onInputValChange("mobile", e.target.value)
                      }
                    ></input>
                  </div>
                </div>

                <div
                  className={[
                    styles.inputView,
                    general.colView,
                    general.width100,
                  ].join(" ")}
                >
                  <div className={[general.rowView]}>
                    <img
                      src={require("../media/icons/password_icon.png")}
                      alt="icon"
                    />
                    <label>Password</label>
                  </div>

                  <div className={[general.posRelative, general.mgT10px].join(" ")}>
                    <input
                      type={isPasswordTypeShow ? "text" : "password"}
                      className={[
                        general.inputBox,
                        general.height50px,
                        general.borderRadius10px,
                        general.bgCustomColor6,
                      ].join(" ")}
                      placeholder="Password (≥6 characters)"
                      autoComplete="new-password"
                      onChange={(e) =>
                        onInputValChange("password", e.target.value)
                      }
                    ></input>

                    <div
                      className="ps-ab ps-tp-50 t-form-y ps-rgt-10"
                      onClick={(e) => updatePasswordtype(e)}
                    >
                      {isPasswordTypeShow ? (
                        <img
                          className="h-w-22"
                          src={require("../icons/eye_open_icon.png")}
                        />
                      ) : (
                        <img
                          className="h-w-22"
                          src={require("../icons/eye_closed_icon.png")}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={[
                    styles.inputView,
                    general.colView,
                    general.width100,
                  ].join(" ")}
                >
                  <div className={[general.rowView]}>
                    <img
                      src={require("../media/icons/password_icon.png")}
                      alt="icon"
                    />
                    <label>Confirm Password</label>
                  </div>

                  <div className={[general.posRelative, general.mgT10px].join(" ")}>
                    <input
                      type={isPasswordTypeShow ? "text" : "password"}
                      className={[
                        general.inputBox,
                        general.height50px,
                        general.borderRadius10px,
                        general.bgCustomColor6,
                      ].join(" ")}
                      placeholder="Password (≥6 characters)"
                      autoComplete="new-password"
                      onChange={(e) =>
                        onInputValChange("confirmPassword", e.target.value)
                      }
                    ></input>

                    <div
                      className="ps-ab ps-tp-50 t-form-y ps-rgt-10"
                      onClick={(e) => updatePasswordtype(e)}
                    >
                      {isPasswordTypeShow ? (
                        <img
                          className="h-w-22"
                          src={require("../icons/eye_open_icon.png")}
                        />
                      ) : (
                        <img
                          className="h-w-22"
                          src={require("../icons/eye_closed_icon.png")}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={[
                    styles.inputView,
                    general.colView,
                    general.width100,
                  ].join(" ")}
                >
                  <div className={[general.rowView]}>
                    <img
                      className="h-w-22"
                      src={require("../media/icons/invite_code_icon.png")}
                    />
                    <label className="mg-l-10">OTP</label>
                  </div>

                  <div className={[general.posRelative, general.rowView, general.mgT10px].join(" ")}>
                    <input
                      type="text"
                      className={[
                        general.inputBox,
                        general.height50px,
                        general.borderRadius10px,
                        general.bgCustomColor6,
                      ].join(" ")}
                      placeholder="OTP"
                      autoComplete="new-password"
                      onChange={(e) =>
                        onInputValChange("verificationCode", e.target.value)
                      }
                    ></input>
                    <div
                      className={[
                        general.height50px,
                        general.width125px,
                        general.viewCenter,
                        general.colorWhite,
                        general.fontS18Px,
                        general.mgL5px,
                        general.borderRadius,
                        general.bgCustomColor2,
                      ].join(" ")}
                      onClick={() => validateMyIP("OTP")}
                    >
                      {pageConst.isOTPSending
                        ? "Sending"
                        : resendOTPTimeLeft > 0
                          ? resendOTPTimeLeft
                          : "OTP"}
                    </div>
                  </div>
                </div>

                <div className={[general.pd15px]}>
                  <div
                    className={[
                      general.height50px,
                      general.width100,
                      general.viewCenter,
                      general.colorWhite,
                      general.fontS20Px,
                      general.mgT10px,
                      general.borderRadius,
                      general.bgCustomColor2,
                    ].join(" ")}
                    onClick={() => validateMyIP("RESET")}
                  >
                    Reset Password
                  </div>
                </div>
              </>
            ) : (
              <div className={[general.pd15px]}>
                <div
                  className={[
                    general.height50px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT10px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => openNewPage(LIVE_CHAT_URL)}
                >
                  Contact Support Team
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
