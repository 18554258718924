import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog/toast';
import '../../MainStyle.css';
import { AVATARS_ARR, API_ACCESS_URL } from '../modals/Constants';
import { getStorage, setStorage } from '../modals/Storage';

function ChangeAvatar() {
  const navigate = useNavigate();
  const toastDialogRef = useRef(null);
  const [avatarURL, setAvatarURL] = useState("");
  const [pageConst, setConstants] = useState({
    pageTitle: "Change Avatar",
    newAvatarId: -1,
    isLoadingShow: false,
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate('/withdraw', { replace: false });
    }
  }

  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
      return { ...previousState, isLoadingShow: data }
    });
  }

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const updateAvatarId = (id) => {
    if (getStorage("avatarId") == id - 1) {
      setConstants(previousState => {
        return { ...previousState, newAvatarId: -1 }
      });
    } else {
      setConstants(previousState => {
        return { ...previousState, newAvatarId: id - 1 }
      });
    }
  }

  const changeAvatarId = () => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-change-avatar',
            'AuthToken': getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "authorization_error") {
          showToast("Authorization Error!");
        } else if (data.status_code == "avatar_id_error") {
          showToast("Password not correct! try again");
        } else if (data.status_code == "success") {
          setStorage("avatarId", pageConst.newAvatarId + 1);
          showToast("Avatar Changed!", 2500, 0, 1);
        } else {
          showToast("Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        showToast("There was a technical issue! Please try again!");
      }
    };

    if (pageConst.newAvatarId >= 0 && pageConst.isLoadingShow == false) {
      updateLoadingStatus(true);
      const formData = { USER_ID: getStorage("uid"), AVATAR_ID: pageConst.newAvatarId + 1 };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const setAvatar = () => {
    function getRandomNumber(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
    }

    let randomNumber = getRandomNumber(0, AVATARS_ARR.length - 1);
    setAvatarURL(AVATARS_ARR[randomNumber].avatarURL);
  }

  useEffect(() => {
    setAvatar();
  }, [avatarURL]);

  return (
    <div className="v-center bg-black ovf-hidden">
      <div className='h-100vh res-wth app-bg'>
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <div className={`pr-v ovf-scrl-y hide-sb zoomAnimView`}>
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction} />

          <div className="col-view pd-20 mg-t-55 mg-b-15">

            <div className='v-center'>
              <div className={`h-w-70 ovf-hidden br-50 ${pageConst.newAvatarId >= 0 ? 'zoomAnimView2' : ''}`}>
                <img src={pageConst.newAvatarId <= -1 ? AVATARS_ARR[getStorage("avatarId") - 1]['avatarURL'] : AVATARS_ARR[pageConst.newAvatarId]['avatarURL']} className={`h-w-70`} alt="icon" />
              </div>
            </div>

            <div className='w-100 g-v-3 g-r-gap-30 mg-t-45'>

              {AVATARS_ARR.map((arrayItem, index) => (
                <div className='v-center' key={arrayItem.id}>
                  <div className="h-w-45 ovf-hidden br-50" onClick={() => updateAvatarId(arrayItem.id)}>
                    <img src={arrayItem.avatarURL} className="h-w-45" alt="icon" />
                  </div>
                </div>
              ))}

            </div>


            <div className={`w-100 mg-t-55 h-50-p ft-sz-20 v-center br-5 cl-golden br-a-golden`} onClick={() => changeAvatarId()}>
              <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow == false ? 'hide-v' : ''}`}></div>
              <span className={`${pageConst.isLoadingShow == true ? 'hide-v' : ''}`}>Update Avatar</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeAvatar;