import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import '../../MainStyle.css';
import { getStorage } from '../modals/Storage';
import general from "../css/General.module.css";

function Privilege() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Privilege",
    recordList: [],
  });

  const topBarClickAction = (data) => {
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}>

        <div className='pr-v ovf-scrl-y hide-sb zoomAnimView'>
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction} />

          <div className="col-view mg-b-15">

            <div className='col-view v-center w-100 pd-5-15 mg-t-45'>

              <div className={`col-view w-100 min-h`}>
                <span className='ft-sz-20 mg-t-10 cl-white'>Invite Bonus</span>
                <span className='ft-sz-15 mg-t-10 cl-white'>You will get the bonus when you refer a person and they complete first recharge.</span>

                <div className="row-view sb-view br-right-t br-left-t mg-t-20 pd-10-15 cl-white" style={{ background: '#6F6F6F' }}>
                  <span>Deposit</span>
                  <span>Bonus</span>
                </div>

                <div className="col-view pd-10-15">

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹100</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹28</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹200</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹48</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹500</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹108</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹1000</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹188</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹5000</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹288</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹10000</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹488</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹50000</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹2888</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹100000</span>
                    <span className='cl-white ft-sz-16 h-80-p br-50 v-center'>₹5888</span>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default Privilege;