import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import BettingDialog from '../dialogs/BettingDialog';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog/toast';
import WinDialog from '../dialogs/WinDialog';
import RuleDialog from '../dialogs/DiceRuleDialog';
import '../../MainStyle.css';
import { setStorage, getStorage } from '../modals/Storage';
import { API_ACCESS_URL } from '../modals/Constants';

function Dice() {
  const toastDialogRef = useRef(null);
  const [remainingSec, setCount] = useState(60);
  const [disableTime, setDisable] = useState(30);

  const [pageConst, setGameData] = useState({
    pageTitle: "Dice",
    period: "",
    gameCode: "DICE",
    remainingSec1: 0,
    remainingSec2: 0,
    remainingMin1: 0,
    remainingMin2: 0,
    diceSlideVal: 48,
    diceMultiply: 1.91,
    isControlEnable: true,
    requestAccountData: true,
    isLoadingShow: false,
    ruleDialogShow: false,
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    mygameRecordsList: [],
    everyOnesOrderList: [],
  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingDialogShow: false,
    bettingDialogTitle: "Join Green",
    bettingDialogTheme: "dlg-thm-green",
    gameBetRoutePath: "route-bet-common",
    gameSelectedBet: "",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === 'visible';
    if (isVisible) {
      getGameData();
    }
  }, []);

  const updatePeriod = (data) => {
    setGameData(previousState => {
      return { ...previousState, period: data[0].game_period_id }
    });
  }

  const updateMyBetRecords = (recentBetData) => {
    let tempData = [];
    const recentBetArr = recentBetData.split(',');

    tempData.push(
      <div key={32} className="row-view mg-t-15 sb-view">
        <span className='ft-sz-14'>{recentBetArr[1]}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center bg-black`}>{recentBetArr[2]}</span>
        <span className='ft-sz-15'>{recentBetArr[3] + ".00"}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
        <span className={`w-65-p ft-sz-15 txt-a-end`} ></span>
      </div>);

    let finalData = [tempData].concat(pageConst.mygameRecordsList);

    setGameData(previousState => {
      return { ...previousState, mygameRecordsList: finalData }
    });
  }

  const updateWinDialogState = (e, state, extra) => {

    if (state == "force-close") {
      setGameData(previousState => {
        return { ...previousState, winDialogShow: false }
      });
    } else if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black v-center activeDialog") {
      setGameData(previousState => {
        return { ...previousState, winDialogShow: state }
      });
    } else if (e == null && state == true) {
      setGameData({ ...pageConst, winDialogShow: state });

      if (extra != "" && extra != null) {
        console.log(extra);

        setGameData(previousState => {
          return { ...previousState, gameWinDetails: extra }
        });
      }
    }

  }

  const updateGameControl = (data) => {
    setGameData(previousState => {
      return { ...previousState, isControlEnable: data }
    });
  }

  const updateReqAcntData = (data) => {
    if (data == "false") {
      setGameData(previousState => {
        return { ...previousState, requestAccountData: false }
      });
    } else {
      setGameData(previousState => {
        return { ...previousState, requestAccountData: true }
      });
    }
  }

  const updateRemainSec = (data, val) => {
    if (data == "1") {
      setGameData(previousState => {
        return { ...previousState, remainingSec1: val }
      });
    } else {
      setGameData(previousState => {
        return { ...previousState, remainingSec2: val }
      });
    }
  }

  const updateRemainMin = (data, val) => {
    if (data == "1") {
      setGameData(previousState => {
        return { ...previousState, remainingMin1: val }
      });
    } else {
      setGameData(previousState => {
        return { ...previousState, remainingMin2: val }
      });
    }
  }

  const updateActiveTab = (data) => {
    setGameData(previousState => {
      return { ...previousState, tabActiveReord: data }
    });
  }

  const updateLoadingStatus = (data) => {
    setGameData(previousState => {
      return { ...previousState, isLoadingShow: data }
    });
  }

  const updateBettingDialogState = (e, data, selected, title) => {

    if (selected != "") {
      setBettingDialogConst(previousState => {
        return { ...previousState, gameSelectedBet: selected }
      });
    }

    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog") {
      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogShow: data }
      });
    } else if (data == true && pageConst.isControlEnable) {
      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogShow: data }
      });

      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogTitle: title }
      });
    } else if (data == "dismiss") {
      setBettingDialogConst(previousState => {
        return { ...previousState, bettingDialogShow: false }
      });
    }
  }

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {

    } else {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }
  }

  const updateGameRuleDialog = (e, data) => {
    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: data }
      });
    } else if (data == "dismiss") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: false }
      });
    } else if (data == "true") {
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }
  }

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  // callback: on bet complete
  const onBetCompleteListener = (status, message = "", extra = "") => {
    if (status == "failed") {
      showToast(message, 2500, 100);
    } else if (status == "success") {
      showToast(message, 2500, 100, 1);
    }
  };

  const updateGameRecords = (data) => {
    let tempData = [], currentPeriod = "", numNeedToRemove = "";
    for (let i = data.length - 1; i > -1; i--) {
      if (i != 29) {

        if (i == 0) {
          currentPeriod = Number(data[i]['period_id'].slice(-3)) + 1;
          if (currentPeriod < 10) {
            currentPeriod = "00" + currentPeriod;
          } else if (currentPeriod < 100) {
            currentPeriod = "0" + currentPeriod;
          }
          numNeedToRemove = 10 - Number(data[i]['period_id'].slice(-1));
        }

        tempData.push(
          <div key={i} className="col-view a-center">
            <div className='ft-sz-17 h-w-28 br-50 cl-white v-center bg-black'>{data[i]['match_result']}</div>
            <span className="ft-sz-14">{data[i]['period_id'].slice(-3)}</span>
          </div>)
      }
    };

    if (data.length > 20) {
      tempData.splice(0, numNeedToRemove);
    }

    tempData.push(
      <div key={31} className="col-view a-center">
        <div className='ft-sz-17 h-w-28 br-50 cl-white v-center bg-yellow'>?</div>
        <span className="ft-sz-14">{currentPeriod}</span>
      </div>)

    setGameData(previousState => {
      return { ...previousState, gameRecordsList: tempData }
    });
  }

  const updateEveryOnesOrder = (data) => {
    setGameData(previousState => {
      return { ...previousState, everyOnesOrderList: data }
    });
  }

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  }

  const updateMyGameRecords = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {

      if (i == 0 && getStorage("recentbet")) {
        const recentBetArr = getStorage("recentbet").split(',');

        if (data[0]['m_period_id'] == recentBetArr[1] && data[0]['m_project'] == recentBetArr[0] && data[0]['m_status'] == "profit") {
          setStorage("recentbet", "", 5);
          updateWinDialogState(null, true, data[i]);
        }
      }

      let tempMatchReslt = "";
      if (data[i]['m_status'] == "profit") {
        if (Number(data[i]['m_profit']) >= 1000000) {
          tempMatchReslt = "+₹" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else if (Number(data[i]['m_profit']) >= 100000) {
          tempMatchReslt = "+₹" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else if (Number(data[i]['m_profit']) >= 10000) {
          tempMatchReslt = "+₹" + getFixedDecimalVal(Number(data[i]['m_profit']) / 1000) + "K";
        } else {
          tempMatchReslt = "+₹" + Number(data[i]['m_profit']);
        }
      } else if (data[i]['m_status'] == "loss") {
        if (Number(data[i]['m_cost']) >= 1000000) {
          tempMatchReslt = "-₹" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else if (Number(data[i]['m_cost']) >= 100000) {
          tempMatchReslt = "-₹" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else if (Number(data[i]['m_cost']) >= 10000) {
          tempMatchReslt = "-₹" + getFixedDecimalVal(Number(data[i]['m_cost']) / 1000) + "K";
        } else {
          tempMatchReslt = "-₹" + Number(data[i]['m_cost']);
        }
      }

      tempData.push(
        <div key={i} className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14'>{data[i].m_period_id}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center bg-black`}>{data[i]['m_color']}</span>
          <span className='ft-sz-15'>{data[i]['m_cost'] + ".00"}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${data[i]['m_result'] != '' ? 'bg-black' : ''}`}>{data[i]['m_result']}</span>
          <span className={`w-65-p ft-sz-15 txt-a-end ${data[i]['m_status'] == "loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
        </div>)
    };

    setGameData(previousState => {
      return { ...previousState, mygameRecordsList: tempData }
    });
  }

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random())
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  }

  const updateEveryOnesOrders = (periodId, remainSec, controlEnabled, everyoneorder) => {

    if (periodId != "") {
      let tempData = [];

      if (everyoneorder.length > 0) {
        tempData = everyoneorder;
      }

      if (controlEnabled == true && getRandBool()) {

        let randBetId = getRandomNum(100, 999, '');
        let randBetAmnt = 10;
        if (getRandBool()) {
          randBetAmnt = getRandomNum(10, 300, 'multi');
        } else {
          randBetAmnt = getRandomNum(10, 1500, 'multi');
        }

        let randBetNum = "";

        if (getRandBool()) {
          randBetNum = getRandomNum(5, 50, '');
        } else {
          randBetNum = getRandomNum(51, 92, '');
        }

        tempData.push(
          <div key={remainSec} className="row-view mg-t-15 sb-view">
            <span className='ft-sz-14'>{periodId}</span>
            <span className='ft-sz-14'>***{randBetId}</span>
            <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center bg-black`}>{randBetNum}</span>
            <span className="ft-sz-14 w-50-p">₹{randBetAmnt}</span>
          </div>)

        updateEveryOnesOrder(tempData.reverse());
      }

    }
  }

  function getGameData() {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-setup-dice',
            'AuthToken': getStorage("secret"),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setCount(data.gamedata[0].game_remain_seconds);
          setDisable(data.gamedata[0].game_disable_time);
          updateGameRecords(data.matchrecords);
          updateMyGameRecords(data.mymatchrecords);
        } else {
          showToast("Something went wrong! Please try again or login!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        console.log(error);
      }
    };

    fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PROJECT=" + pageConst.gameCode);
  }

  function setUpTimer(data) {
    if (data == "true") {
      updateReqAcntData("true");
    }

    if (pageConst.requestAccountData == true) {
      updateReqAcntData("false");
      getGameData();
    }
  }

  const onRangeSliderChange = (e) => {
    let rangeVal = e.target.value, diceMultiplierVal = 0;

    if (rangeVal >= 4 && rangeVal <= 92 && pageConst.isControlEnable) {
      if (rangeVal < 10) {
        diceMultiplierVal = 1.03 + (92 - rangeVal) * 0.2;
      } else if (rangeVal < 30) {
        diceMultiplierVal = 1.03 + (92 - rangeVal) * 0.07;
      } else if (rangeVal < 50) {
        diceMultiplierVal = 1.03 + (92 - rangeVal) * 0.02;
      } else {
        diceMultiplierVal = 1.03 + (92 - rangeVal) * 0.01;
      }

      setGameData(previousState => {
        return { ...previousState, diceMultiply: diceMultiplierVal.toFixed(2) }
      });

      setGameData(previousState => {
        return { ...previousState, diceSlideVal: e.target.value }
      });
    }
  }

  useEffect(() => {

    document.addEventListener("visibilitychange", handleVisibilityChange)
    setUpTimer("false");

    const interval = setInterval(() => {
      if (remainingSec >= 0) {
        setCount(remainingSec - 1);

        if (remainingSec <= 0) {
          setUpTimer("true");
          setCount(60);
          updateEveryOnesOrder([]);
        } else {

          function splitIntoArray(num) {
            return Array.from(String(num), Number);
          }

          const minutes = Math.floor(remainingSec / 60);
          const seconds = remainingSec % 60;

          if (minutes < 10) {
            updateRemainMin("1", 0);
            updateRemainMin("2", minutes);
          } else {
            var numArr = splitIntoArray(minutes);
            updateRemainMin("1", numArr[0]);
            updateRemainMin("2", numArr[1]);
          }

          if (seconds < 10) {
            updateRemainSec("1", 0);
            updateRemainSec("2", seconds);
          } else {
            var numArr = splitIntoArray(seconds);
            updateRemainSec("1", numArr[0]);
            updateRemainSec("2", numArr[1]);
          }

          if (remainingSec < disableTime && pageConst.isControlEnable == true) {
            updateGameControl(false);
          } else if (remainingSec > disableTime && pageConst.isControlEnable == false) {
            updateGameControl(true);
          }

          updateEveryOnesOrders(pageConst.period, remainingSec, pageConst.isControlEnable, pageConst.everyOnesOrderList);
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }

  }, [remainingSec]);


  return (
    <div className="v-center">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
        <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction} />
        <LoadingDialog intentData={pageConst} />
        <WinDialog intentData={pageConst} updateState={updateWinDialogState} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />
        <BettingDialog
          gameCode={pageConst.gameCode}
          dialogData={bettingDialogConst}
          updateState={updateBettingDialogState}
          onBetCompleteListener={onBetCompleteListener}
          updateMyBetRecords={updateMyBetRecords} />

        <div className="game-manage-view col-view mg-t-45">

          <div className="col-view bg-white">

            <div className="row-view sb-view pd-15">
              <div className="col-view">
                <span className="m-bottom-n">Period</span>
                <span className="f-size-max t-color-blk ltr-s ft-wgt-600">{pageConst.period}</span>
              </div>

              <div className="col-view a-right">
                <span className="m-bottom-n">Count Down</span>
                <div className="t-countview">
                  <span>{pageConst.remainingMin1}</span>
                  <span>{pageConst.remainingMin2}</span>
                  :
                  <span>{pageConst.remainingSec1}</span>
                  <span>{pageConst.remainingSec2}</span>
                </div>
              </div>
            </div>

            <div className='mg-t-5 pd-15'>

              <div className="row-view w-100 bg-black br-10 bx-shdw-blk a-center">

                <div className="col-view pd-20 v-center w-100">
                  <span className='cl-white'>Less than</span>
                  <span className='cl-white ft-sz-20'>{pageConst.diceSlideVal}</span>
                </div>
                <span className='line-v'></span>
                <div className="col-view pd-20 v-center w-100">
                  <span className='cl-white'>Multiplier</span>
                  <span className='cl-white ft-sz-20'>{pageConst.diceMultiply}</span>
                </div>

              </div>

              <div className='pr-v mg-t-25'>
                <input type="range" className="slider-range" onChange={(e) => onRangeSliderChange(e)} min="1" max="99" value={pageConst.diceSlideVal}></input>
                <div className="slidPro" style={{ width: `${pageConst.diceSlideVal}%` }}></div>
              </div>

              <div className='w-100 ft-sz-10 row-view sb-view pd-10'>
                <span>1</span>
                <span>|</span>
                <span>25</span>
                <span>|</span>
                <span>50</span>
                <span>|</span>
                <span>75</span>
                <span>|</span>
                <span>99</span>
              </div>

              <div className={`w-100 v-center mg-t-20 pd-20 br-10 cl-white ${pageConst.isControlEnable ? 'bg-drk-blue bx-shdw-blk' : 'bg-grey-2'}`} onClick={() => updateBettingDialogState(null, true, pageConst.diceSlideVal, 'Less than ' + pageConst.diceSlideVal)}>
                Less than {pageConst.diceSlideVal}
              </div>

            </div>

          </div>

          <div className="col-view br-right-t br-left-t mg-t-25 bg-white">

            <div className="tab-slct-v w-100">
              <div className="v-center tab-in-v pd-10 ft-sz-18 ft-wgt-700 w-100 active">Record</div>
            </div>

            <div className="col-view pd-10">
              <div className="row-view sb-view">
                <span>Parity Records</span>
                <Link className="rc-btn txt-deco-n" to={"/gamerecords?GC=" + pageConst.gameCode}>more</Link>
              </div>

              <div className="g-v-10 mg-t-15">
                {pageConst.gameRecordsList}
              </div>
            </div>

          </div>

          <div className="col-view br-right-t br-left-t mg-t-25 mg-b-15 bg-white">

            <div className="tab-slct-v w-100">
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord == "everyoneorder" ? 'active' : ''}`} onClick={() => updateActiveTab('everyoneorder')}>Everyone's Order</div>
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord == "myorder" ? 'active' : ''}`} onClick={() => updateActiveTab('myorder')}>My Order</div>
            </div>

            <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord != "everyoneorder" ? 'hide-v' : ''}`}>
              <div className="row-view sb-view">
                <span>Period</span>
                <span>User</span>
                <span>Select</span>
                <span>Point</span>
              </div>

              <div className="col-view mg-t-15 fd-up-anim">
                {pageConst.everyOnesOrderList}
              </div>
            </div>

            <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord != "myorder" ? 'hide-v' : ''}`}>
              <div className="row-view sb-view">
                <span className='ft-sz-14'>Period</span>
                <span className='ft-sz-14 mg-l-30'>Select</span>
                <span className='ft-sz-14'>Point</span>
                <span className='ft-sz-14'>Result</span>
                <span className='ft-sz-14'>Amount</span>
              </div>

              <div className="col-view mg-t-15">
                {pageConst.mygameRecordsList}
              </div>

              <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/mygamerecords"}>More</Link>
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Dice;